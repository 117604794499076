.alert-container {
    padding: 10px;
}

.alert-container__content {
    background: white;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    max-width: 400px;
    @include media-breakpoint-up(sm) {
        padding: 20px;
    }
}

.alert-title {
    font-size: 22px;
    line-height: 22px;
    color: $dark-blue;
}

.alert-message {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 15px;
}

.alert-confirm-btn,
.alert-cancel-btn {
    padding: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 13px;
    outline: none;
    border: none;
    color: white;
    border-radius: 8px;
    width: 100%;
    opacity: 1;
    &:hover {
        opacity: 0.85;
    }
}

.alert-confirm-btn {
    margin-right: 20px;
    background: $dark-blue;
    max-width: 150px;
    font-weight: bold;
}

.alert-cancel-btn {
    box-shadow: none !important;
    background: transparent;
    color: black;
    max-width: 100px;
}
