.slider {
    // width: 100%;
    max-width: 180px;
    margin: 0 auto;
    // position: relative;
    overflow: scroll;
    border-radius: 5px;
    transform: translate3d(0, 0, 0);
    background: whitesmoke;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.slider::-webkit-scrollbar {
    display: none;
}

@include make-mover-animation();

.slider-up {
    max-height: 580px;
    overflow: scroll;
    transform: translate3d(0, 0, 0);
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.slider-up::-webkit-scrollbar {
    display: none;
}

.mover-up {
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease-out;
    animation: moveSlideshowUp 10s linear infinite;
}

@keyframes moveSlideshowUp {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-100%);
    }
}