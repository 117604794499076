// Colors
$dark-blue: #32004a;
$logo-color: $dark-blue;
$dark-grey: #545454;
$dark-brown: #392912;
// $grey: #666;
$light-grey: #d7d7d7;
$grey: #aaaaaa;
$backDropColor: rgba(32, 28, 48, 0.4);
$font-size-xlarge: 21px;
$font-size-large: 17px;
$font-size-medium: 14px;
$font-size-small: 12px;
$font-size-xxlg: 35px;
$font-size-xlg: 25px;
$font-size-lg: 20px;
$font-size-md: 16px;
$font-size-sm: 12px;
$s-size: 12px;
$m-size: 14px;
$l-size: 12px;
$xl-size: 22px;
$tablet-breakpoint: 680px;
// $tablet-breakpoint: 620px;
$desktop-breakpoint: 960px;

$colors: (
    "money-pool-color": #89e993,
    "money-pool-light-color": #eaffe7,
    "upkeep-stipends-color": #f3a183,
    "upkeep-stipends-light-color": #fff1ec,
    "fundraiser-color": #ad5790,
    "fundraiser-light-color": #ffe3f5,
    "green": #50e3c2,
    "normal-green": #0ead1e,
    "light-green": #01dbca,
    "lighter-green": #11ffca,
    "xlighter-green": #dcf8c6,
    "dark-green": darkgreen,
    "light-normal-blue": #6861e9,
    "lighter-normal-blue": #c2cdf5,
    "red": #d40707,
    "blue": #2b74bc,
    "light-blue": #3a5d9e,
    "darker-blue": #112a46,
    "orange-red": #db4f37,
    "normal-blue": #0000ff,
    "dodgerblue": dodgerblue,
    "off-white": #f7f7f7,
    "light-brown": #e5ddd5,
    "dark-blue": $dark-blue,
    "lighter-blue": #e3ecf3,
    // "lighter-blue": #e3faff,
    "dark-grey": #545454,
    "dark-brown": #392912,
    "orange": #eaae5f,
    "light-orange": #f8e7bc,
    "grey": #aaaaaa,
    // "lighter-grey": #f4f4f4,
    "light-grey": $light-grey,
    "lighter-grey": #f7f7f7,
    "pale-navy": #f4f2ff,
    "black": black,
    "white": white,
    "light-pink": #ffdede,
    "light-black": #242424,
    "lighter-normal-green": #c7f8cc,
    "charcoal": #333333,
    "transparent": transparent,
    "back-drop-color": $backDropColor,
    "light-back-drop-color": rgba(32, 28, 48, 0.1),
) !default;

.hover-red {
    &:hover {
        color: red !important;
    }
}

.hover-orange-red {
    &:hover {
        color: #db4f37 !important;
    }
}

.hover-dodgerblue {
    &:hover {
        color: dodgerblue !important;
    }
}

.hover-dark-blue {
    &:hover {
        color: $dark-blue !important;
    }
}

.hover-bg-light-grey {
    &:hover {
        background: #f2f2f2 !important;
    }
}

.bg-grad-dodgerblue-green {
    background-image: linear-gradient(to right, dodgerblue, #50e3c2) !important;
}

.bg-grad-lightgreen-orange {
    background-image: linear-gradient(to right, #01dbca, #eaae5f) !important;
}

.bg-grad-blue-lightblue {
    background-image: linear-gradient(to right, #2b74bc, #3a5d9e) !important;
}

.bg-grad-lightgreen-blue {
    background-image: linear-gradient(to right, #01dbca, #2b74bc) !important;
}

.bg-grad-lightgreen-lightblue {
    background-image: linear-gradient(to right, #01dbca, #3a5d9e) !important;
}

.bg-grad-orange-darkblue-bottom-right {
    background-image: linear-gradient(to bottom right, #eaae5f, #3a5d9e) !important;
}

.bg-grad-darkblue-orange-bottom-right {
    background-image: linear-gradient(to bottom right, $dark-blue, #eaae5f) !important;
}

.bg-grad-lighterblue-lighterorange {
    background-image: linear-gradient(#9980a5, #f4cfa2) !important;
}

.bg-grad-orange-darkblue-right {
    background-image: linear-gradient(to right, #d69e61, $dark-blue) !important;
}

.bg-grad-orange-darkblue {
    background-image: linear-gradient(#d69e61, $dark-blue) !important;
}

.bg-grad-darkblue-orange {
    background-image: linear-gradient(to right, $dark-blue, #eaae5f) !important;
}

.bg-grad-pink-lightpink-bottom-right {
    background-image: linear-gradient(to bottom right, #ff9a9e, #fad0c4) !important;
}

.bg-grad-green-yellow-bottom-right {
    background-image: linear-gradient(to bottom right, #96fbc4, #f9f586) !important;
}

.bg-grad-lightblue-blue-bottom-right {
    background-image: linear-gradient(to bottom right, #74ebd5, #9face6) !important;
}

.bg-grad-greenblue-orange-bottom-right {
    background-image: linear-gradient(to bottom right, #16a085, #f4d03f) !important;
}

.bg-grad-light-blue-lighter-green-right {
    background-image: linear-gradient(to bottom right, #7f80d5, #91eae4) !important;
}
