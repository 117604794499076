.home-background-image {
    position: relative;
    height: 100%;
    width: 100%;
}

.home-background-image::before {
    content: "";
    background-image: url("/components/images/home_page_backgound.png");
    // background-size: cover;
    background-size: contain;
    background-repeat: repeat;
    // background-position: right top;
    //   background-position: center top;
    background-position: 50% 50%;
    background-attachment: fixed;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.05;
    z-index: -1;
}

// div {
//     position: relative;
// }

// .row1-container {
//     padding: 50px 0px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     flex-direction: column;
//     .row1-container__left,
//     .row1-container__right {
//         padding: 20px 0px;
//     }
//     @media (min-width: $desktop-breakpoint) {
//         flex-direction: row;

//         .row1-container__left {
//             max-width: 400px;
//         }
//         .row1-container__right {
//             img {
//                 width: 550px;
//                 display: block;
//             }
//         }
//     }
// }

// .row2-container {
//     padding: 50px 0px;
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     @media (min-width: $desktop-breakpoint) {
//         flex-direction: row;
//         .row2-container__left {
//             width: 250px;
//         }
//         .row2-container__right {
//             img {
//                 width: 500px;
//                 display: block;
//             }
//         }
//     }
// }
