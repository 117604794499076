.stepup-container {
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    justify-content: space-between;
    .setup-container__left {
        width: 100%;
        max-width: 360px;
        h1 {
            color: $orange;
            font-size: 35px;
            line-height: 35px;
        }
        p {
            font-size: 21px;
        }
    }
    .setup-container__right {
        margin-right: 15%;
        width: 100%;
        max-width: 620px;
    }

    .inner-container-top__content__left {
        h3 {
            color: $logo-color;
            font-size: 23px;
        }

        p {
            align-items: center;
            display: flex;
            font-size: 20px;
            i {
                margin-right: 10px;
                font-size: 9px;
            }
        }
    }
    // .setup-container__right__content {
    //     background: white;
    //     padding: 20px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     width: 100%;
    //     max-width: 400px;
    //     height: 250px;
    //     border-radius: 10px;
    //     border: 1px solid #ccc;
    // }
}
