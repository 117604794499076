.footer {
    background: $logo-color;
    color: white;
    padding: 25px 0px;
    width: 100%;
    p {
        padding-top: 10px;
        text-align: center;
        font-size: $s-size;
        span {
            font-size: $font-size-medium;
        }
    }
    @media (min-width: $desktop-breakpoint) {
        padding: 50px 0px;
        p {
            padding-top: 30px;
        }
    }
}

.footerlinks {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    @media (min-width: $desktop-breakpoint) {
        flex-direction: row;
    }
}

.footerlinks_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    width: 100%;
    text-align: center;
    padding: 15px 0px;
    @media (min-width: $desktop-breakpoint) {
        text-align: left;
        padding: 0px;
    }
    .footerlinks_box_title {
        font-size: $font-size-large;
        font-weight: bold;
    }
    ul {
        margin: 0px;
        padding: 0px;
        list-style: none;
        a {
            color: inherit;
            text-decoration: none;
        }
        li {
            font-size: $font-size-medium;
            padding: 10px 0px;
            cursor: pointer;
            display: block;

            @media (min-width: $desktop-breakpoint) {
                display: table;
            }
            &:hover {
                color: $blue;
            }
        }
    }
}
