// Text truncate
// Requires inline-block or block for proper styling

@mixin make-text-truncate() {
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
