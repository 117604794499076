.input {
  border: 1px solid #f2f2f2;
  background: white;
  border-radius: 6px;
  outline: none;
  padding: 8px;
  width: 100%;

  &:focus {
    border: 1px solid $logo-color;
  }
}

.focus-none {
  &:focus {
    border: none;
  }
}

.custom-input {
  // background: aliceblue;
  // border: 1px solid #ddd;
  border: 1px solid #f2f2f2;
  background: white;
  border-radius: 6px;
  height: 40px;
  outline: none;
  font-size: 14px;
  padding: 3px 6px;
  margin-top: 5px;
  width: 100%;
  // box-shadow: 0 0 0 rgb(255, 255, 255);
  // &:focus {
  //   border: 1px solid $logo-color;
  // }

  &:focus {
    border: 1px solid $logo-color;
  }
}

.react-phone-number-input {
  font-size: 16px;
}

.react-phone-number-input input {
  font-size: 16px;
}

// From 'react-phone-number-input/style.css'
.PhoneInput {
  input {
    width: 100%;
    height: 30px;
    background: transparent;
    border: none;
  }
}

// From 'react-phone-number-input/style.css'
.PhoneInput--focus {
  border: 1px solid $logo-color;
  input {
    outline: none;
    border: none;
  }
}

.custom-formik-field {
  padding: 0;
  align-items: center;
  display: flex;
  input {
    @extend .input;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .currency-symbol {
    margin-top: 5px;
    font-size: 25px;
    padding: 0px 10px;
    background: #e3ecf3;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.input-title-container {
  @extend .input;
  padding: 0px 3px;
  height: auto;
  background: white;
}

.select {
  @extend .input;
}

.input-textarea {
  @extend .input;
  resize: none;
  height: auto;
}

.input-label {
  font-size: 14px;
  display: block;
}

input:disabled {
  border: 1px solid #ddd !important;
  // background: whitesmoke;
  opacity: 0.6;
  cursor: not-allowed;
}

.react-select-container {
  .react-select__control {
    @extend .input;
    width: 100%;
    border: 1px solid transparent;
    box-shadow: none;
    &:hover {
      border: 1px solid transparent;
    }
  }
  .react-select__control:focus-within {
    border: 1px solid $logo-color;
    box-shadow: none;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .react-select__value-container {
    height: 35px;
    min-height: 35px;
    padding: 0 5px;
  }
}
