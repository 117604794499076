.input-group {
  // padding: 0px 10px;
  display: table;
  width: 100%;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

// .input-group-secondary {
//   width: 700px;
//   display: inline-block;
// }

.checkbox {
  align-items: center;
  display: flex;
  input[type="checkbox"] {
    margin-right: 10px;
  }
  label {
    font-size: 12px;
    margin-right: 20px;
  }
}

.column {
  padding: 8px 0px;
  width: 100%;
  display: inline-table;
}

.column-left
// ,
// .column-large-left
{
  @extend .column;
  @media (min-width: $tablet-breakpoint) {
    width: 50%;
    float: left;
    padding-right: 10px;
  }
}

.column-right
// ,
// .column-large-right
{
  @extend .column;
  @media (min-width: $tablet-breakpoint) {
    width: 50%;
    float: left;
    padding-left: 10px;
  }
}

// .column-large-left {
//   max-width: 100%;
//   padding-right: 0px;
//   @media (min-width: $tablet-breakpoint) {
//     padding-right: 15px;
//   }
//   @media (min-width: $desktop-breakpoint) {
//     max-width: 400px;
//     padding-right: 10px;
//     // display: inline-block;
//   }
// }

// .column-large-right {
//   padding-left: 15px;
//   max-width: 100%;
//   padding-left: 0px;
//   @media (min-width: $tablet-breakpoint) {
//     padding-left: 15px;
//   }
//   @media (min-width: $desktop-breakpoint) {
//     max-width: 400px;
//     padding-left: 10px;
//     // display: inline-block;
//   }
// }

.label-left {
  font-size: 13px;
  width: 145px;
  width: 170px;
  font-weight: bold;
  display: inline-block;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex: 1;
}

.vertical-align {
  align-items: center;
}

.horizontal-align {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.ok-message,
.error-message {
  font-size: $font-size-small;
  display: block;
}

.ok-message {
  color: green;
}

.error-message {
  color: red;
}

// .delete-file-backdrop {
//   width: 100%;
//   cursor: pointer;
//   height: 100%;
//   background: rgba(32, 28, 48, 0.5);
//   position: absolute;
// }

// .hover-img-link:hover {
//   color: red;
// }

.delete-img-icon {
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: orangered;
  position: absolute;
  &:hover {
    color: red;
  }
}

.max-text-width {
  color: darkblue;
  margin-top: -6px;
  margin-bottom: 5px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
