.content-container {
  margin: 0 auto;
  padding: 0px 6px;
  width: 100%;
  max-width: 1500px;

  @include media-breakpoint-up(md) {
    padding: 0px 20px;
  }
}
