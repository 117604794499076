.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 6px;
    padding-right: 6px;
    // background-color: rgba(0, 0, 0, 0.3);
    background: $backDropColor;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;

    .ReactModal__Overlay {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open {
        opacity: 1;
        transform: translateX(0px);
    }

    .ReactModal__Overlay--before-close {
        opacity: 0;
        transform: translateX(-100px);
    }
}

.modal {
    background: #ffffff;
    box-shadow: none;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    // padding: 20px 10px;
    padding: 10px;
    border: none;
    outline: none;
    // width: 420px;

    .ReactModal__Overlay {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open {
        opacity: 1;
        transform: translateX(0px);
    }

    .ReactModal__Overlay--before-close {
        opacity: 0;
        transform: translateX(-100px);
    }
}

.setup-modal {
    @extend .modal;
    // background: #f2f2f2;
    background: white;
    padding: 0px;
    width: 100%;
    max-width: 840px;
    border-radius: 15px;
    .setup-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        // background-image: linear-gradient(to right, #d69e61 , #1E0E6E);
        background-image: linear-gradient(to right, #eaae5f, $dark-blue);
        padding: 20px 15px;
        display: flex;
        justify-content: flex-end;

        .fa-close {
            font-size: 23px;
            background: #ff0000;
            padding: 5px 7px;
            color: white;
            border-radius: 50%;
            cursor: pointer;
        }
    }
    .setup-modal-progress {
        margin-top: 12px;
        margin-bottom: 12px;
        border: 1px solid $light-grey;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        background: white;
        span {
            width: 15px;
            height: 15px;
            font-size: 9px;
            color: white;
            background: grey;
            margin-right: 5px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            display: flex;
        }
        label {
            font-size: 10px;
        }

        .done,
        .active,
        .not-active {
            padding: 5px 3px;
            display: flex;
            flex-grow: 1;
            color: #aaaaaa;
            align-items: center;
            justify-content: center;
            @include media-breakpoint-up(sm) {
                justify-content: flex-start;
            }
        }
        .done {
            // border-top-left-radius: 20px;
            // border-bottom-left-radius: 20px;
            border-radius: 0px;
            color: white;
            background: #eaae5f;
            span {
                background: black;
            }
        }
        .active {
            // display: flex;
            border-radius: 15px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            // padding-left: 10px;
            // padding-right: 10px;
            color: white;
            background: #eaae5f;
            span {
                background: black;
            }
        }
        .not-active {
            // display: flex;
            // border-radius: 20px;
            // padding: 10px 20px;
            // background: white;
        }

        .first-page {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }

        @media (min-width: $tablet-breakpoint) {
            border-radius: 30px;
            span {
                width: 24px;
                height: 24px;
                font-size: 12px;
            }
            label {
                font-size: 13px;
            }
            .done,
            .active,
            .not-active {
                padding: 12px 10px;
            }
            .active {
                border-radius: 30px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
            .first-page {
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
            }
        }
    }
}

.upload-container {
    padding: 15px 0px;
    padding-top: 10px;
    display: flex;
}

.upload-container__left {
    width: 50%;
    border-radius: 10px;
}

.dashed {
    height: 100%;
    background: #eee9ff;
    color: $logo-color;
    border: 2px dashed $logo-color;
    align-items: center;
    justify-content: center;
    display: flex;
    span {
        font-size: 15px;
    }
}

.upload-container__right {
    width: 50%;
    // background: green;
}

// .sub-modal-container {
//     padding: 30px 0px;
// }

.close-modal-icon {
    top: -23px;
    right: -23px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: white;
    border: 2px solid whitesmoke;
    background: rgba(32, 28, 48, 0.8);
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    .fa-close {
        color: whitesmoke;
        font-size: 16px;
    }
}

.max-width {
    width: 100%;
    max-width: 420px;
}

.min-width {
    width: 100%;
    max-width: 340px;
}

.dashboard-sub-container {
    width: 100%;
    max-width: 580px;
}

.with-shadow {
    box-shadow: 2px 2px 20px 1px;
}

.black-background {
    background: black;
}

.modal--large {
    width: 100%;
    max-width: 780px;
}

.modal__body {
    // padding-bottom: 10px;
}

.modal__header {
    display: flex;
    align-items: center;
}

.modal__header__text {
    font-size: 15px;
    color: $logo-color;
    flex: 1;
    font-weight: bold;
    h1 {
        text-align: center;
        font-size: $font-size-xlarge;
    }
}

.btn-close {
    font-size: $font-size-xlarge;
    cursor: pointer;
    color: gray;
    color: orangered;
    &:hover {
        color: red;
    }
}

// .modal-section-left-right {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     // align-items: center;
//     @media (min-width: $tablet-breakpoint) {
//         flex-direction: row;
//     }
// }

// .modal-section-left {
//     width: 100%;
//     margin-right: 30px;
//     // max-width: 300px;
//     // @media (min-width: $desktop-breakpoint) {
//     //     max-width: 50%;
//     // }
//     .image-container {
//         img {
//             width: 100%;
//             height: 100%;
//             border-radius: 0px;
//         }
//     }
// }

// .modal-section-right {
//     width: 100%;
//     max-width: 280px;
//     display: none;
//     .modal-section-right__content {
//         padding: 15px;
//         width: 100%;
//         border-radius: 8px;
//         background: #ffffff;
//         box-shadow: 2px 2px 20px 1px;
//         // margin-top: 20px;
//         // margin-bottom: 5px;
//         // margin-left: 0px;
//     }
//     @media (min-width: $tablet-breakpoint) {
//         display: block;
//     }
// }

.modal-content {
    padding: 5px 20px;
}

// .modal-section-right__content__title {
//     padding-top: 10px;
//     align-items: center;
//     display: flex;
// }

.background-photo-image {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: black;
    padding-top: 10px;
    margin: 10px;
}

.take-photo-background-icon-top {
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 650px;
    justify-content: space-evenly;
    display: flex;
    position: fixed;
    z-index: 4000;
    .icon-font-size {
        font-size: 22px;
    }
    @media (min-width: 500px) {
        top: 25%;
        .icon-font-size {
            font-size: 30px;
        }
    }
}

.take-photo-background-icon-bottom {
    @extend .take-photo-background-icon-top;
    background-color: transparent;
    top: auto;
    bottom: 37%;
    @media (min-width: 380px) {
        bottom: 30%;
    }
    @media (min-width: 500px) {
        bottom: 22%;
    }
}

.background-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    background: rgba(32, 28, 48, 0.4);
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

/* override alert dialog defaults */
.react-confirm-alert-overlay {
    // background: rgba(0, 0, 0, 0.5);
    background: $backDropColor;
    z-index: 20000;
}
// .react-confirm-alert {
//     background: white;
//     width: 80%;
//     padding: 1em;
// }
/* custom alert dialog styles */
// .add-dialog h3 {
//     margin: 0;
// }
// .add-dialog-buttons {
//     float: right;
// }
// .add-dialog-buttons button + button {
//     margin-left: 0.5em;
// }
