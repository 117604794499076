.pagination-container {
    margin: 20px 0px;
    justify-content: center;
    width: 100%;
    display: flex;
    color: $logo-color;
}

.pagination-container__content {
    padding: 12px 15px;
    align-items: center;
    justify-content: space-between;
    border-radius: 25px;
    background-color: white;
    max-width: 800px;
    width: 100%;
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.arrow-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    i {
        font-size: 22px;
    }
    span {
        font-size: 12px;
    }
}
.pagination-text {
    display: flex;
    align-items: center;
    span {
        // margin: 0px 3px;
        // padding: 0px 3px;
        font-size: 12px;
        cursor: pointer;
    }
    .active {
        width: 20px;
        height: 20px;
        color: white;
        background-color: $logo-color;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        display: flex;
    }
}
